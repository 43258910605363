import { useEffect, useRef, FunctionComponent } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import { filteredSortedOrders } from '../../helpers/utils';

import { IUser } from '../../types/IUsers';
import { ILocation, ILocations } from '../../types/ILocations';
import { IOrder, IOrders, OrderDeliveryType } from '../../types/IOrders';
import { Category, Subcategory } from '../../types/DashboardCategories';

import { l18n } from '../../common/l18n'
import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { Operation, SortingDirection, SortingField, SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { Loader } from '../Loader/Loader';
import { OrderTile } from '../OrderTile/OrderTile';
import { Result } from '../Result/Result';
import { allCategories } from '../Dashboard/Dashboard';
import { useDummyData } from '../../context/DummyDataProvider';

import BeachGraphic from '../../assets/images/graphics/RelaxingBeach.svg';
import HomeGraphic from '../../assets/images/graphics/RelaxingHome.svg';
import PoolGraphic from '../../assets/images/graphics/RelaxingPool.svg';


const useLateOrder = false;
const lateOrderId = 112;
const lateOrderDelay = 1000 * 5;

type OrdersProps = {
  user: IUser
  location: ILocation
  closeTimestamp: number
  partnerLocations: ILocations | null
  query: string
  category: Category
  subcategory: Subcategory
  orders: IOrders | null
  setOrders: Function
  refreshKey: any
  onRefresh: Function
  ordersKey: any
  setOrdersKey: Function
  setTileModalOpen: Function
  globalCompactView: boolean
}

export const Orders: FunctionComponent<OrdersProps> = ({ user, location, closeTimestamp, partnerLocations, query, category, subcategory, orders, setOrders, refreshKey, onRefresh, ordersKey, setOrdersKey, setTileModalOpen, globalCompactView }) => {
  // orders state
  const nodeRef = useRef(null);

  // context
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData: any = useDummyData();

  // use useEffect hook to update the Orders state variables when data is returned from the API
  useEffect(() => {
    (async () => {
      if (dummyData.state.useDummyData) {
        // console.log("set orders from dummy data");
        // console.log(dummyData.state.orders);
        if (!useLateOrder || dummyData.state.dashboard.numOrders.open !== -1) {
          setOrders(dummyData.state.orders);
          setOrdersKey(refreshKey);
        } else {
          setOrders(dummyData.state.orders.filter((order: any) => order.id !== lateOrderId));
          setTimeout(() => {
            setOrders(dummyData.state.orders);
            setOrdersKey(refreshKey);
          }, lateOrderDelay);
        }
      } else {
        // request Cart data from API
        const requestBody = {
          filter_chain: [{
            field: {
              LocationCode: location!.code
            },
            operation: Operation.EqualTo
          }],
          pagination: {
            page_number: 1,
            page_size: 1000,
          },
          sorting: [{
            field: SortingField.CreatedAt,
            direction: SortingDirection.Ascending
          }]
        };

        Promise.all([
          apiHelper.getLocationOrders(location?.code),
          api.getCarts(requestBody)
        ]).then((values: Array<any>) => {
          const [orderResponse, cartResponse] = values;
          const combinedOrders = [];

          // handle orders
          if (orderResponse.ok) {
            const orders = JSON.parse(orderResponse.body.data!);
            combinedOrders.push(...orders.filter((order: IOrder) => order.delivery_type_code !== OrderDeliveryType.InStore));
          } else {
            console.log('Unable to retrieve Orders from database');
          }

          // handle carts
          if (cartResponse.data.status === 'success') {
            const carts = cartResponse.data.data.carts;
            combinedOrders.push(...carts);
          } else {
            console.log('Unable to retrieve Carts from database');
          }

          // set state
          setOrders(combinedOrders);
          setOrdersKey(refreshKey);
        })
      }
    })();
  }, [dummyData.state.useDummyData, refreshKey]);

  return (
    <>
      {orders === null ? 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Loader />
        </div>
      :
        <>
          {orders === null ? 
            <Result wasSuccessful={false} />
          :
            <>
              {orders.length === 0 || orders[0] === null ? 
                <Result wasSuccessful={false} message={l18n.label.result_orders_unavailable_modified} />
              :
                <SwitchTransition>
                  <CSSTransition
                    key={category}
                    nodeRef={nodeRef}
                    timeout={200}
                    classNames="orders-category"
                    unmountOnExit
                  >
                    <StyledOrders className="Orders" ref={nodeRef}>
                      {/* <button className="Orders_refresh" onClick={() => onRefresh()}>Refresh Orders</button> */}
                      {filteredSortedOrders(orders, category, query, subcategory, closeTimestamp, dummyData.state.useDummyData).length > 0 ?
                        <>
                          {filteredSortedOrders(orders, category, query, subcategory, closeTimestamp, dummyData.state.useDummyData).map((order: IOrder, i: number) => {
                            if (!order.hasOwnProperty('is_visible') || order.is_visible === true)
                              return (
                                <OrderTile
                                  key={`${order.id}-${order.id}-${order.updated_at}`}
                                  order={order}
                                  user={user}
                                  category={category}
                                  subcategory={subcategory}
                                  partnerLocations={partnerLocations}
                                  closeTimestamp={closeTimestamp}
                                  onRefresh={onRefresh}
                                  setTileModalOpen={setTileModalOpen}
                                  orders={orders}
                                  setOrders={setOrders}
                                  globalCompactView={globalCompactView}
                                />
                              );
                            else
                              return null;
                          })}
                        </>
                      :
                        <>
                          {query !== '' ?
                            <p>No results were found in '{allCategories.find(cat => cat.id === category)?.label}'.</p>
                          :
                            <>
                              <div className="Orders_noOrders">
                                <p>There are currently no orders in this category.</p>
                              </div>
                              {/* {category === 'open' &&
                                <div className="Orders_noOrders">
                                  <p>Put your feet up - there are no new orders!</p>
                                  <img src={BeachGraphic} alt="Graphic: Pharmacist relaxing on the beach" />
                                </div>
                              }
                              {category === 'overdue' &&
                                <div className="Orders_noOrders">
                                  <p>There are no overdue orders.</p>
                                </div>
                              }
                              {category === Category.AwaitingDelivery &&
                                <div className="Orders_noOrders">
                                  {subcategory === Subcategory.RequiresAction &&
                                    <>
                                      <p>You can take a breath - there are no orders that require action.</p>
                                      <img src={HomeGraphic} alt="Graphic: Pharmacist relaxing at home" />
                                    </>
                                  }
                                  {subcategory === Subcategory.DispatchOrganised &&
                                    <>
                                      <p>There are no orders ready for dispatch.</p>
                                    </>
                                  }
                                </div>
                              }
                              {category === Category.AwaitingPickup &&
                                <div className="Orders_noOrders">
                                  <p>Sit back and relax - there are no orders awaiting click and collect!</p>
                                  <img src={PoolGraphic} alt="Graphic: Pharmacist relaxing in the pool" />
                                </div>
                              }
                              {category === Category.Complete &&
                                <div className="Orders_noOrders">
                                  <p>There are no completed orders.</p>
                                </div>
                              }
                              {category === Category.CancelledRefunded &&
                                <div className="Orders_noOrders">
                                  <p>There are no cancelled or refunded orders.</p>
                                </div>
                              } */}
                            </>
                          }
                        </>
                      }
                    </StyledOrders>
                  </CSSTransition>
                </SwitchTransition>
              }
            </>
          }
        </>
      }
    </>
  );
}

const StyledOrders = styled.div`
  position: relative;
  margin: 0 auto;

  .Orders_refresh {
    display: none;
    position: absolute;
    left: -130px;
    padding: 6px;
  }

  .Orders_noOrders {
    p {
      margin-top: 25px;
    }

    img {
      margin-top: 20px;
    }
  }
`