import { FunctionComponent, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { Cart, CreateCartRequest, CreateCartRequestItemsInner, IntegrationTypes, PolicyFeature, SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { ILocation } from '../../types/ILocations';
import { formatCartLink, formatTimestampISO } from '../../helpers/utils';

import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { InputField } from '../InputField/InputField';
import { SelectionButton } from '../SelectionButton/SelectionButton';
import { SelectionGroupInputType } from '../SelectionGroup/SelectionGroup';
import { SelectInput, SelectInputOption } from '../SelectInput/SelectInput';
import { CopyText } from '../CopyText/CopyText';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';

import strongProLogo from '../../assets/images/logos/StrongPro.png';
import closeIcon from '../../assets/images/icons/Close.svg';


export const expirationOptions: Array<SelectInputOption> = [
  {
    value: '',
    label: 'Never'
  },
  {
    value: 1,
    label: '1 hour'
  },
  {
    value: 24,
    label: '1 day'
  },
  {
    value: 24 * 3,
    label: '3 days'
  },
  {
    value: 24 * 7,
    label: '7 days'
  },
  {
    value: 24 * 30,
    label: '30 days'
  },
]


type StrongProCampaignModalProps = {
  show: boolean
  location: ILocation
  onClose?: Function
}

export const StrongProCampaignModal: FunctionComponent<StrongProCampaignModalProps> = ({ show, location, onClose }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  
  const useDrugLookup = true;

  // state
  const [campaignName, setCampaignName] = useState('');
  const [drugName, setDrugName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [pde, setPde] = useState('');
  const [price, setPrice] = useState('');
  const [includesGst, setIncludesGst] = useState(false);
  const [expiration, setExpiration] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCopyId, setShowCopyId] = useState(false);
  const [generatedCart, setGeneratedCart] = useState<Cart | null>(null);
  
  // typeahead state
  const [typeaheadIsLoading, setTypeaheadIsLoading] = useState(false);
  const [typeaheadOptions, setTypeaheadOptions] = useState<Array<any>>([]);

  // Typeahead search handler -- FHIR expand query
  const onSearch = async (query: string): Promise<void> => {
    setTypeaheadIsLoading(true);

    // set value directly for string-only entry
    setDrugName(query);

    const valueSet = 'http://snomed.info/sct?fhir_vs=refset/929360041000036105';
    const endpoint = 'https://r4.ontoserver.csiro.au/fhir';
    const url = endpoint + '/ValueSet/$expand?' + new URLSearchParams({
      '_format': 'json',
      'url': valueSet,
      'filter': query
    }).toString();

    fetch(url, { mode: 'cors', method: 'GET' })
      .then(resp => resp.json())
      .then(json => {
        setTypeaheadIsLoading(false);
        if (json && json.expansion && json.expansion.contains) {
          const options = json.expansion.contains;
          // console.log(options);
          setTypeaheadOptions(options);
        }
      });
  }

  const onSelect = (selectedOption: Array<any>): void => {
    // Typeahead returns an array of length 1
    const selectedDrug = selectedOption[0];
    if (selectedDrug && selectedDrug.display) {
      setDrugName(selectedDrug.display);
    }
  }

  // methods
  const handleCreateCart = (): void => {
    setIsLoading(true);
    setErrorMessage('');

    // default request body
    let requestBody: CreateCartRequest = {
      location_code: location!.code,
      is_enabled: true,
      feature: PolicyFeature.Scripts,
      requires_delivery: true
    }

    // campaign name
    if (campaignName.trim() !== '') {
      requestBody.name = campaignName;
    }

    // format item data if required
    if (drugName.trim() !== '' || barcode.trim() !== '') {
      requestBody.items = formattedCartItemData();
    }

    // generate expiry timestamp if required
    if (expiration !== '') {
      const now = new Date();
      const validTo = now.setTime(now.getTime() + (parseFloat(expiration)*60*60*1000));
      requestBody.valid_to = formatTimestampISO(new Date(validTo));
    }

    console.log('request body', requestBody);

    api.createCart(requestBody).then((response) => {
      // handle success
      setIsLoading(false);
      const cart: Cart = response.data.data;
      console.log(cart);
      setGeneratedCart(cart);
      setShowCopyId(true);
    })
    .catch(error => {
      // handle error
      setIsLoading(false);
      // setErrorMessage(error.response.data.message);
      setErrorMessage('Error creating StrongPro campaign.');

      console.log('error!')
      console.log(error)
    })
  }

  const formattedCartItemData = (): Array<CreateCartRequestItemsInner> => {
    let name, sku;
    if (barcode.trim() !== '') {
      sku = barcode;

      if (drugName.trim() !== '') {
        name = drugName;
      } else {
        name = barcode;
      }
    } else {
      const fallback = uuidv4();
      name = fallback;
      sku = fallback;
    }

    let item: CreateCartRequestItemsInner = {
      sku,
      name,
      qty: 1
    }

    if (price.trim() !== '') {
      const priceFloat = parseFloat(price);
      item.price = price;
      item.tax = includesGst ? (priceFloat / 11).toFixed(2) : '0';
    }

    return [item];
  }

  const resetState = (): void => {
    setCampaignName('');
    setDrugName('');
    setBarcode('');
    setPde('');
    setPrice('');
    setIncludesGst(false);
    setExpiration('');

    setIsLoading(false);
    setErrorMessage('');
    setShowCopyId(false);
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      resetState();
    }, transitionDuration + 200);

    if (onClose) onClose();
  }

  const formIsInvalid = (): boolean => {
    return false; // currently no required fields

    // const formEl = (document.getElementById('form_strongpro-campaign-modal') as HTMLFormElement);
    // return campaignName.trim() === ''
    //   || drugName.trim() === ''
    //   || barcode.trim() === ''
    //   || pde.trim() === ''
    //   || price.trim() === ''
    //   || expiration.trim() === ''
    //   || (formEl && !formEl.checkValidity());
  }

  return (
    <StyledStrongProCampaignModal className="StrongProCampaignModal">
      <Modal show={show}>
        <img className="StrongProCampaignModal_logo" src={strongProLogo} alt="StrongPro logo" />
        
        {!showCopyId ?
          <>
            <Heading heading="Enter the details of your StrongPro campaign:" />
            
            <form id="form_strongpro-campaign-modal">
              <div className="divider">
                <InputField type="text" id="campaignName" label="Campaign Name" value={campaignName} maxLength={255} onChange={(e: ChangeEvent) => setCampaignName((e.target as HTMLInputElement).value)} />
                
                {useDrugLookup ?
                  <div className="local-bootstrap typeahead">
                    <label htmlFor="drugLookup">Drug Name</label>
                    <AsyncTypeahead
                      id="drugLookup"
                      isLoading={typeaheadIsLoading}
                      labelKey={(option: any) => `${option.display}`}
                      onSearch={onSearch}
                      onChange={onSelect}
                      options={typeaheadOptions}
                      placeholder="Start typing drug name..."
                    />
                  </div>
                :
                  <InputField type="text" id="drugName" label="Drug Name" value={drugName} maxLength={255} onChange={(e: ChangeEvent) => setDrugName((e.target as HTMLInputElement).value)} />
                }
                
                <InputField type="text" id="barcode" label="Barcode" value={barcode} maxLength={255} regex={/^\d+$/} onChange={(e: ChangeEvent) => setBarcode((e.target as HTMLInputElement).value)} />
                <div className="StrongProCampaignModal_columns">
                  <InputField type="number" id="price" label="Price" placeholder="$" min={0} step="any" regex={/^[0-9]*\.?[0-9]{0,2}$/} value={price} onChange={(e: ChangeEvent) => setPrice((e.target as HTMLInputElement).value)} />
                  <SelectionButton type={SelectionGroupInputType.Checkbox} checkbox id="gst" label="This price includes GST" selected={includesGst} value="" onChange={(e: ChangeEvent) => setIncludesGst(includesGst => !includesGst)} />
                </div>
              </div>
              
              <SelectInput id="campaignExpiration" label="Campaign Expiration" options={expirationOptions} value={expiration} onChange={(e: ChangeEvent) => setExpiration((e.target as HTMLInputElement).value)} />

              {errorMessage &&
                <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
                  <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                    <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                  </Alert>
                </div>
              }
              <Button type={ButtonType.Primary} tall text="Create Campaign" onClick={handleCreateCart} loading={isLoading} disabled={formIsInvalid()} />
              <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
            </form>
          </>
        :
          <>
            <Heading heading="Your campaign has been created" />
            <p className="bold">Copy the campaign ID below into your StrongPro campaign to send a trackable cart to your patients.</p>
            {/* <CopyText text={formatCartLink(generatedCart!.reference)} /> */}
            <CopyText text={generatedCart!.reference} />

            <img src={closeIcon} alt="Close" className="Close button top_right" onClick={handleClose} draggable="false" />
          </>
        }
      </Modal>
    </StyledStrongProCampaignModal>
  );
}

const StyledStrongProCampaignModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .StrongProCampaignModal_logo {
      height: 47px;
      width: auto;
      margin-bottom: 20px;
    }

    .Heading {
      margin-bottom: 26px;
    }

    form {
      margin-top: 40px;

      .divider {
        padding-bottom: 10px;
        margin-bottom: 34px;
      }

      .InputField {
        margin: 24px 0;
      }

      select, input {
        font-size: 0.875rem !important; // 14px
      }

      .StrongProCampaignModal_columns {
        display: grid;
        gap: 10px;
        grid-gap: 10px;
        grid-template-columns: 275px 1fr;
        margin-bottom: -15px;

        & > div {
          margin: auto 0 24px 0;

          input {
            margin-bottom: 0;
          }
        }
      }
    }

    p.bold {
      margin-bottom: 30px;
    }

    .Button_primary {
      margin-top: 50px;
      margin-bottom: 23px;
    }

    .top_right {
      top: 35px;
      right: 35px;
    }
  }
`