import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';
import { formatTimestamp, getNumItemsString, getOrderNumber, getOrderUrgency, objectIsCart, orderRequiresContact, parseTimestamp } from '../../helpers/utils';

import { IOrder, OrderDeliveryType, CourierType, OrderStatus, OrderUrgency, DeliveryModelCode, IOrders } from '../../types/IOrders';
import { ILocations } from '../../types/ILocations';
import { Timestamp } from '../../types/Timestamp';
import { Category, Subcategory } from '../../types/DashboardCategories';
import { IUser, UserRoleCode } from "../../types/IUsers";
import { IDelivery } from '../../types/IDelivery';

import { useDummyData } from '../../context/DummyDataProvider';
import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { Cart, Order, SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { TimeAgo } from '../TimeAgo/TimeAgo';
import { Button, ButtonType } from '../Button/Button';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import { Countdown, CountdownType } from '../Countdown/Countdown';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { OrderItems } from '../OrderItems/OrderItems';
import { OrderNotes } from '../OrderNotes/OrderNotes';
import { ChangeDeliveryMethodModal } from '../ChangeDeliveryMethodModal/ChangeDeliveryMethodModal';
import { EditCustomerDetailsModal } from '../EditCustomerDetailsModal/EditCustomerDetailsModal';
import { OrderNotesModal } from '../OrderNotesModal/OrderNotesModal';
import { TransferOrderModal } from '../TransferOrderModal/TransferOrderModal';
import { CancelDispatchModal } from '../CancelDispatchModal/CancelDispatchModal';
import { OrderHistoryModal } from '../OrderHistoryModal/OrderHistoryModal';
import { OrderDocumentsModal } from '../OrderDocumentsModal/OrderDocumentsModal';
import { CustomDeliveryModal } from '../CustomDeliveryModal/CustomDeliveryModal';
import { EditOrderCategoryModal } from '../EditOrderCategoryModal/EditOrderCategoryModal';
import { InProgressModal } from '../InProgressModal/InProgressModal';
import { CartBadges } from '../CartBadges/CartBadges';
import { CopyCartLinkModal } from '../CopyCartLinkModal/CopyCartLinkModal';
import { DisableCartModal } from '../DisableCartModal/DisableCartModal';
import { EditCartNoteModal } from '../EditCartNoteModal/EditCartNoteModal';
import { OrderBadges } from '../OrderBadges/OrderBadges';

import historyIcon from '../../assets/images/icons/History.svg';
import tagCheckIcon from '../../assets/images/tags/Check.svg';

type OrderTileProps = {
  user: IUser
  order: IOrder
  category: Category
  subcategory: Subcategory
  highlight?: boolean
  partnerLocations: ILocations | null
  closeTimestamp: number
  onRefresh: Function
  setTileModalOpen: Function
  orders: IOrders
  setOrders: Function
  globalCompactView: boolean
};

export const OrderTile: FunctionComponent<OrderTileProps> = ({
  order,
  user,
  category,
  subcategory,
  highlight,
  partnerLocations,
  closeTimestamp,
  onRefresh,
  setTileModalOpen,
  orders,
  setOrders,
  globalCompactView
}) => {
  const navigate = useNavigate();
  const dummyData: any = useDummyData();
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showCancelDispatchModal, setShowCancelDispatchModal] = useState(false);
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [showCustomDeliveryModal, setShowCustomDeliveryModal] = useState(false);
  const [showChangeDeliveryMethodModal, setShowChangeDeliveryMethodModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showInProgressModal, setShowInProgressModal] = useState(false);
  
  const [showCopyCartLinkModal, setShowCopyCartLinkModal] = useState(false);
  const [showDisableCartModal, setShowDisableCartModal] = useState(false);
  const [showEditCartNoteModal, setShowEditCartNoteModal] = useState(false);

  const [currentNoteId, setcurrentNoteId] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveries, setDeliveries] = useState<any>();
  const [localCompactView, setLocalCompactView] = useState<boolean | null>(null);

  const useCompactView = (): boolean => {
    if (localCompactView === null) {
      return globalCompactView;
    } else {
      return localCompactView;
    }
  }

  const handlePickItems = (): void => {
    if (order.delivery_type_code === OrderDeliveryType.Pickup || order.delivery_type_code === OrderDeliveryType.ExternalPickup) {
      navigate(`/send/process/click-and-collect/${order.id}`);
    } else if (order.delivery_type_code === OrderDeliveryType.Custom) {
      navigate(`/send/process/custom-delivery/${order.id}`);
    } else if (order.delivery_type_code === OrderDeliveryType.SameDay) {
      navigate(`/send/process/same-day-delivery/${order.id}`);
    } else {
      navigate(`/send/process/delivery/${order.id}`);
    }
  }

  const handleCreateReturn = (): void => {
    navigate(`/send/process/create-refund/${order.id}`);
  }

  const handleBeginCollection = (): void => {
    navigate(`/send/process/customer-collection/${order.id}`);
  }

  // DELIVERY CANCELLATION API INTEGRATION
  const handleDeliveryCancellation = (): void => {
    setShowCancelDispatchModal(true);
  }

  const handleMarkAsDelivered = (): void => {
    setShowCustomDeliveryModal(true);
  }

  const handleDeliveriesByOrder = async (orderId: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      if (deliveries || [OrderDeliveryType.Custom, OrderDeliveryType.SameDay, OrderDeliveryType.Pickup].includes(order.delivery_type_code)) {
        setShowDocumentsModal(true);
      } else {
        setIsLoading(true);
        apiHelper
          .getDeliveriesByOrder(orderId)
          .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
              resolve(response.body.data);
              const data = typeof response.body.data === "string"
                ? JSON.parse(response.body.data)
                : response.body.data;
              setDeliveries(data);
              setShowDocumentsModal(true)
            } else {
              reject(response.body.message);
            }
          })
      }
    })
  };

  const dropdownMenuItems: Array<DropdownMenuItem> = [
    {
      id: 'editCustomerDetails',
      label: 'Edit delivery address',
      categories: [Category.OpenOrders],
      onClick: () => { setShowCustomerDetailsModal(true) }
    },
    {
      id: 'editDeliveryMethod',
      label: 'Change delivery method',
      categories: [Category.OpenOrders],
      subcategories: [Subcategory.IncomingOrders],
      requiredUserRoles: [UserRoleCode.Admin],
      onClick: () => { setShowChangeDeliveryMethodModal(true) }
    },
    {
      id: 'transfer',
      label: 'Transfer order',
      categories: [Category.OpenOrders],
      subcategories: [Subcategory.IncomingOrders],
      onClick: () => { setShowTransferModal(true) }
    },
    {
      id: 'manualOverride',
      label: 'Manual override',
      categories: [Category.OpenOrders],
      onClick: () => { setShowEditCategoryModal(true) },
    },
    {
      id: 'reopen',
      label: 'Reopen order',
      categories: [Category.RequiresManifest],
      onClick: () => { handleDeliveryCancellation() },
    },
    {
      id: 'cancelDispatch',
      label: 'Cancel dispatch',
      categories: [Category.DispatchCollection],
      subcategories: [Subcategory.DispatchPickup],
      courierTypes: [CourierType.Custom],
      onClick: () => { handleDeliveryCancellation() },
    },
    {
      // duplicate cancelDispatch entry to support DoorDash & Aramex "closed" orders
      id: 'cancelDispatch',
      label: 'Cancel dispatch',
      categories: [Category.ClosedOrders],
      subcategories: [Subcategory.CompletedOrders],
      courierTypes: [CourierType.Doordash, CourierType.Aramex],
      onClick: () => { handleDeliveryCancellation() },
    },
    // {
    //   id: 'cancelOrder',
    //   label: 'Cancel & refund order',
    //   categories: [Category.OpenOrders, Category.DispatchCollection],
    //   subcategories: [Subcategory.IncomingOrders, Subcategory.FraudOrders, Subcategory.PendingPayment, Subcategory.DispatchCollection],
    //   requiredUserRoles: [UserRoleCode.Admin, UserRoleCode.Manager],
    //   onClick: () => { handleCreateReturn() },
    // },
    // {
    //   id: 'refundOrder',
    //   label: 'Refund order',
    //   categories: [Category.ClosedOrders],
    //   subcategories: [Subcategory.CompletedOrders, Subcategory.PartialRefundOrders],
    //   requiredUserRoles: [UserRoleCode.Admin, UserRoleCode.Manager],
    //   onClick: () => { handleCreateReturn() },
    // },
    {
      id: 'refundOrder',
      label: 'Refund order',
      categories: [Category.OpenOrders, Category.DispatchCollection, Category.ClosedOrders],
      subcategories: [Subcategory.IncomingOrders, Subcategory.FraudOrders, Subcategory.DispatchCollection, Subcategory.CompletedOrders, Subcategory.PartialRefundOrders],
      requiredUserRoles: [UserRoleCode.Admin, UserRoleCode.Manager],
      onClick: () => { handleCreateReturn() },
    },
    {
      id: 'addNotes',
      label: 'Add order notes',
      onClick: () => {
        setcurrentNoteId(-1);
        setShowNotesModal(true);
      },
    },
    {
      id: 'history',
      label: 'View order history',
      onClick: () => {
        setShowHistoryModal(true);
      },
    },
    {
      id: 'documents',
      label: 'View documents',
      onClick: () => { handleDeliveriesByOrder(order.id) },
    },
    {
      id: 'inProgress',
      label: subcategory === Subcategory.InProgress ? 'Mark as New Order' : 'Mark as In Progress',
      subcategories: [Subcategory.IncomingOrders, Subcategory.InProgress],
      onClick: () => { setShowInProgressModal(true) },
    },
    {
      id: 'compact',
      label: 'Toggle Compact View',
      onClick: () => { setLocalCompactView(val => !val) },
    },
  ];

  const cartDropdownMenuItems: Array<DropdownMenuItem> = [
    {
      id: 'copyCartUrl',
      label: 'Copy cart link',
      onClick: () => { setShowCopyCartLinkModal(true) }
    },
    {
      id: 'disableCart',
      label: 'Cancel cart',
      onClick: () => { setShowDisableCartModal(true) }
    },
    {
      id: 'editCartNotes',
      label: 'Edit order notes',
      onClick: () => { setShowEditCartNoteModal(true) }
    },
  ]

  const getDropdownMenuItems = (): Array<DropdownMenuItem> => {
    return dropdownMenuItems.filter(dropdown_item => {
      // dropdown menu item is included if:

      const generic = (
        // no required categories specified, or category matches
        (!dropdown_item.categories || dropdown_item.categories.includes(category)) &&
        // no required subcategory specified, or subcategory matches
        (!dropdown_item.subcategories || dropdown_item.subcategories.includes(subcategory)) &&
        // no required courier types specified, or courier type matches
        (!dropdown_item.courierTypes || (order.courier_type_code && dropdown_item.courierTypes.includes(order.courier_type_code))) &&
        // no required user role specified, or use role matches
        (!dropdown_item.requiredUserRoles || dropdown_item.requiredUserRoles.includes(user.role_code))
      );


      const specific = (
        // refundOrder -- order is not already refunded
        ((dropdown_item.id === 'refundOrder' && order.status_code !== OrderStatus.Refunded) || dropdown_item.id !== 'refundOrder')
      )

      return generic && specific;
    });
  }

  const partialRefundItems = order.items.map((item: any) => {
    return { ...item, qtyToRefund: item.qty_refunded || 0 }
  });

  const timeAgoPrefix = (): string => {
    switch (order.status_code) {
      case OrderStatus.AwaitingPickup:
        return 'Processed';
      case OrderStatus.AwaitingProcessing:
      case OrderStatus.AwaitingCourier:
      case OrderStatus.AwaitingDropoff:
        return 'Prepared';
      case OrderStatus.Complete:
        return 'Ordered';
      case OrderStatus.Cancelled:
        return 'Ordered';
      default:
        return '';
    }
  }

  const orderTime = (): Timestamp => {
    // default to updated, fall back to ordered
    let timestamp = order.updated_at || order.created_at;

    if (
      order.status_code === OrderStatus.New ||
      order.status_code === OrderStatus.Complete ||
      order.status_code === OrderStatus.Cancelled ||
      order.status_code === OrderStatus.Refunded
    ) {
      timestamp = order.created_at;
    }

    return timestamp;
  }

  const deliveryTypeStyle = (): OrderDeliveryType | 'cart_delivery' | 'cart_payment' | 'cart_payment_only' => {
    if (objectIsCart(order)) {
      return (order as any).requires_delivery ? 'cart_delivery' : 'cart_payment';
    } else {
      return order.delivery_type_code ? order.delivery_type_code : 'cart_payment_only';
    }
  }

  const handleEditNote = (index: number): void => {
    setcurrentNoteId(index);
    setShowNotesModal(true);
  }

  const doordashDeadline = (): Date => {
    const created = parseTimestamp(order.created_at);
    const deadline = new Date(created.getTime() + (1000 * 60 * 45)); // 45 minutes after creation
    return deadline;
  }

  useEffect(() => {
    if (!objectIsCart(order) &&  deliveries === undefined && order.status_code !== OrderStatus.New && order.status_code !== OrderStatus.AwaitingProcessing) {
      apiHelper.getDeliveriesByOrder(order.id)
        .then((response) => {
          if (response.status === 200) {
            const data = typeof response.body.data === "string"
              ? JSON.parse(response.body.data)
              : response.body.data;
            if (data.length > 0) {
              setDeliveries(data);
            }
          }
        })
    }
  }, [order, deliveries])
  
  useEffect(() => {
    setLocalCompactView(globalCompactView);
  }, [globalCompactView])

  // watch modal variables and setTileModalOpen
  useEffect(() => {
    if ([
      showHistoryModal,
      showTransferModal,
      showCancelDispatchModal,
      showCustomerDetailsModal,
      showNotesModal,
      showDocumentsModal,
      showCustomDeliveryModal,
      showChangeDeliveryMethodModal,
      showEditCategoryModal,
    ].every(showModal => showModal === false)) {
      setTileModalOpen(false);
    } else {
      setTileModalOpen(true);
    }
  }, [
    showHistoryModal,
    showTransferModal,
    showCancelDispatchModal,
    showCustomerDetailsModal,
    showNotesModal,
    showDocumentsModal,
    showCustomDeliveryModal,
    showChangeDeliveryMethodModal,
    showEditCategoryModal,
  ])

  return (
    <>
      <div style={{ position: 'relative', marginTop: '25px' }} data-order-id={user.role_code === UserRoleCode.Admin ? order.id : null}>
        <StyledOrderTile deliveryType={deliveryTypeStyle()} className={`OrderTile ${getOrderUrgency(order, closeTimestamp) === OrderUrgency.Overdue && 'highlight'} ${order.status_code === OrderStatus.Archived && 'archived'} ${useCompactView() && 'compact'}`}>

          {/* dev elements */}
          {/* <p>{order.id}</p> */}
          {/* <p>{order.status_code}</p> */}
          {/* <p>{order.created_at}</p> */}
          {/* <p>{order.updated_at}</p> */}
          {/* <p>{order.completed_at}</p> */}

          {/* absolute positioned elements */}
          <StyledTimeAgo className="OrderTile_timeAgo italic" urgency={getOrderUrgency(order, closeTimestamp)}>
            <>{timeAgoPrefix()} <TimeAgo isoDate={orderTime()} /></>
          </StyledTimeAgo>
          <div className="OrderTile_menu">
            <DropdownMenu items={objectIsCart(order) ? cartDropdownMenuItems : getDropdownMenuItems()} setDropdownOpen={setTileModalOpen} />
          </div>

          {/* relative positioned elements */}
          <div className="OrderTile_badges">
            {objectIsCart(order) ?
              <CartBadges cart={(order as any)} />
            :
              <>
                <OrderBadges order={order} category={category} compact={useCompactView()} />
                {/* COMPACT ORDER IDENTIFIER */}
                {useCompactView() &&
                  <p className="OrderTile_compactInformation bold">
                    <span>{getOrderNumber(order)}</span>
                    <span className="compactInformation_divider">|</span>
                    <span>{getNumItemsString(order)}</span>
                  </p>
                }
              </>
            }
          </div>

          {objectIsCart(order) ?
            <>
              {!useCompactView() &&
                <>
                  <OrderDetails order={order} phone={order.delivery_phone !== null} email={order.delivery_email !== null} cart />
                  <div className="OrderTile_divider"></div>
                  <OrderItems order={order} orderItems={order.items} accordion fields={['name', 'quantity', 'price']} noRefundAlert cart />
                  {((order as any).note !== '' && (order as any).note !== null) &&
                    <OrderNotes order={order} onEditNote={() => setShowEditCartNoteModal(true)} />
                  }
                </>
              }
            </>
          :
            <>
              {!useCompactView() &&
                <>
                  {dummyData.state.useDummyData &&
                    <div className="OrderTile_tags">
                      {(
                        order.status_code === OrderStatus.AwaitingPickup ||
                        order.status_code === OrderStatus.Complete
                      ) &&
                        <p>
                          <img src={tagCheckIcon} alt="" draggable="false" />
                          Collection email sent
                        </p>
                      }
                      {(
                        order.status_code === OrderStatus.AwaitingProcessing ||
                        order.status_code === OrderStatus.AwaitingDropoff ||
                        order.status_code === OrderStatus.AwaitingCourier
                      ) &&
                        <p>
                          <img src={tagCheckIcon} alt="" draggable="false" />
                          {(order.status_code === OrderStatus.AwaitingCourier && order.delivery_type_code !== OrderDeliveryType.Custom) ? 'Courier pickup scheduled' : 'Order packaged'}
                        </p>
                      }
                      {(order.status_code === OrderStatus.Complete && order.delivery_type_code === OrderDeliveryType.ExternalPickup) &&
                        <p>
                          <img src={tagCheckIcon} alt="" draggable="false" />
                          Collected on {formatTimestamp(order.updated_at)}
                        </p>
                      }
                      {/* <p>
                        <img src={tagTildeIcon} alt="" draggable="false" />
                        Tag with tilde icon
                      </p> */}
                    </div>
                  }
                  {(user.role_code === UserRoleCode.Admin && order.delivery_type_code === OrderDeliveryType.SameDay && order.status_code === OrderStatus.New) &&
                    <Countdown type={CountdownType.Order} pickupAvailable={true} deadlineDate={doordashDeadline()} colourOverride />
                  }
                  <div style={{ position: 'relative' }}>
                    {order.status_code !== OrderStatus.New &&
                      <img
                        className="OrderTile_history button"
                        src={historyIcon}
                        alt="Show order history"
                        draggable="false"
                        onClick={() => getDropdownMenuItems().find(item => item.id === 'history')!.onClick()}
                      />
                    }
                    <OrderDetails order={order} phone email />
                    <div className="OrderTile_divider"></div>
                    {(dummyData.state.useDummyData && order.id === 112 && [OrderStatus.AwaitingCourier, OrderStatus.Complete].includes(order.status_code)) &&
                      <p className="OrderTile_trackingLink" key={`trackinglink-${order.id}`}>
                        <>Tracking No. <a href="https://auspost.com.au/mypost/beta/track/details/36A8E5000301" target="_blank">36A8E5000301</a></>
                      </p>
                    }
                    {deliveries &&
                      <>
                        {deliveries.map((delivery: IDelivery, i: number) => {
                          if (delivery && (delivery.courier_tracking_code && delivery.courier_ref)) {
                            return (
                              <p className="OrderTile_trackingLink" key={`trackinglink-${order.id}-${i}`}>
                                {(delivery.courier_tracking_code && delivery.courier_tracking_code.startsWith('https://')) &&
                                  <a href={delivery.courier_tracking_code} target="_blank">Tracking Link</a>
                                }
                                {(delivery.courier_tracking_code && delivery.courier_tracking_code.startsWith('https://') === false) &&
                                  <>Tracking No. <a href={`https://auspost.com.au/mypost/beta/track/details/${delivery.courier_tracking_code}`} target="_blank">{delivery.courier_tracking_code}</a></>
                                }
                                {(delivery.courier_tracking_code && delivery.courier_ref) &&
                                  <span> | </span>
                                }
                                {delivery.courier_ref &&
                                  <span>Courier Reference: {delivery.courier_ref}</span>
                                }
                              </p>
                            )
                          } else {
                            return null;
                          }
                        })}
                      </>
                    }
                    <OrderItems order={order} orderItems={order.items} partialRefundItems={partialRefundItems} accordion fields={['name', 'quantity', 'price']} noRefundAlert />
                  </div>
                </>
              }
              {order.notes.length > 0 &&
                <OrderNotes order={order} onEditNote={handleEditNote} numNotes={3} />
              }
              
              {/* PRIMARY BUTTON */}
              {!useCompactView() &&
                <>
                  {(order.status_code === OrderStatus.New || order.status_code === OrderStatus.OnHold) &&
                    <>
                      {order.delivery_type_code !== OrderDeliveryType.ExternalPickup ?
                        // All non-robot orders
                        <Button type={ButtonType.Primary} text="Pick Items" onClick={handlePickItems} />
                        :
                        // Button state for robot orders depends on reservation status
                        <div className={order.initial_reservation_status !== 'complete' ? 'fakeDisabledButton' : ''}>
                          <Button type={ButtonType.Primary} text={order.initial_reservation_status === 'complete' ? 'Pick Items' : 'Awaiting stock confirmation'} onClick={handlePickItems} />
                        </div>
                      }
                    </>
                  }
                  {(order.status_code === OrderStatus.AwaitingPickup && order.delivery_type_code !== OrderDeliveryType.ExternalPickup) &&
                    <Button type={ButtonType.Primary} text="Begin Customer Collection" onClick={handleBeginCollection} />
                  }
                  {/* {order.status_code === OrderStatus.AwaitingCourier && <Button type={ButtonType.Primary} text="Mark as Picked Up" onClick={handleMarkAsDelivered} />} */}
                  {/* {order.status_code === OrderStatus.AwaitingDropoff && <Button type={ButtonType.Primary} text="Mark as Dropped Off" onClick={handleMarkAsDelivered} />} */}
                  {(order.status_code === OrderStatus.AwaitingCourier && order.delivery_type_code === OrderDeliveryType.Custom) &&
                    <Button type={ButtonType.Primary} text="Mark as Delivered" onClick={handleMarkAsDelivered} />
                  }

                  {/* temp fix until scheduled manifest sync is implemented */}
                  {/* {order.status_code === OrderStatus.Complete && <Button type={ButtonType.Primary} text="Create a Refund" onClick={handleCreateReturn} />} */}
                  {/* {(
                    [OrderStatus.Complete, OrderStatus.AwaitingCourier, OrderStatus.AwaitingDropoff].includes(order.status_code) &&
                    order.delivery_type_code !== OrderDeliveryType.Custom
                  ) &&
                    <Button type={ButtonType.Primary} text="Create a Refund" onClick={handleCreateReturn} />
                  } */}
                </>
              }
            </>
          }
        </StyledOrderTile>
      </div>

      {/* modals */}
      {!objectIsCart(order) ?
        // Order modals
        <>
          <CancelDispatchModal show={showCancelDispatchModal} user={user} order={order} onClose={() => setShowCancelDispatchModal(false)} onRefresh={onRefresh} />
          <OrderHistoryModal show={showHistoryModal} order={order} onClose={() => setShowHistoryModal(false)} />
          <OrderDocumentsModal show={showDocumentsModal} order={order} deliveries={deliveries} partnerLocations={partnerLocations} onClose={() => setShowDocumentsModal(false)} />
          <ChangeDeliveryMethodModal show={showChangeDeliveryMethodModal} order={order} onClose={() => setShowChangeDeliveryMethodModal(false)} onRefresh={onRefresh} />
          {!(order as unknown as Order).cart_id &&
            <EditCustomerDetailsModal show={showCustomerDetailsModal} order={order} onClose={() => setShowCustomerDetailsModal(false)} onRefresh={onRefresh} />
          }
          <OrderNotesModal show={showNotesModal} order={order} currentNoteId={currentNoteId} onClose={() => setShowNotesModal(false)} onRefresh={onRefresh} />
          <TransferOrderModal show={showTransferModal} order={order} partnerLocations={partnerLocations} onClose={() => setShowTransferModal(false)} onRefresh={onRefresh} />
          <CustomDeliveryModal show={showCustomDeliveryModal} user={user} order={order} deliveries={deliveries} onClose={() => setShowCustomDeliveryModal(false)} onRefresh={onRefresh} orders={orders} setOrders={setOrders} />
          <EditOrderCategoryModal show={showEditCategoryModal} order={order} onClose={() => setShowEditCategoryModal(false)} onRefresh={onRefresh} />
          <InProgressModal show={showInProgressModal} order={order} onClose={() => setShowInProgressModal(false)} onRefresh={onRefresh} />
        </>
      :
        // Cart modals
        <>
          <CopyCartLinkModal show={showCopyCartLinkModal} cart={(order as any)} onClose={() => setShowCopyCartLinkModal(false)} />
          <DisableCartModal show={showDisableCartModal} cart={(order as any)} onClose={() => setShowDisableCartModal(false)} onRefresh={onRefresh} />
          <EditCartNoteModal show={showEditCartNoteModal} cart={(order as any)} onClose={() => setShowEditCartNoteModal(false)} onRefresh={onRefresh} />
        </>
      }
    </>
  );
}

const StyledOrderTile = styled.div<{ deliveryType: OrderDeliveryType | 'cart_delivery' | 'cart_payment' | 'cart_payment_only' }>`
  width: 100%;
  height: fit-content;
  min-height: 252px;
  margin: 25px 0 0 0;
  padding: 52px 52px 43px 52px;

  border-radius: 6px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);
  overflow: unset;

  // COMPACT
  &.compact {
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: unset;

    .OrderTile_timeAgo {
      top: 24px;
      right: 85px;
    }

    .OrderTile_menu {
      top: 23px;
    }
    
    .OrderTile_badges {
      width: calc(100% - 120px);
    }

    .OrderTile_compactInformation {
      margin: 0 0 0 10px;
      font-size: 1.125rem; // 18px

      .compactInformation_divider {
        margin: 0 12px;
      }
    }
  }

  // ARCHIVED
  &.archived {
    background: #F4FBFF;
  }

  // HIGHLIGHT
  &.highlight {
    border-top: 1px solid ${colours.alert};
    border-right: 1px solid ${colours.alert};
    border-bottom: 1px solid ${colours.alert};
  }

  // LEFT GRADIENT
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px 0 0 6px;
    width: 10px;
    ${props => handleDeliveryType(props.deliveryType)};
  }

  // DROPDOWN MENU
  .OrderTile_menu {
    position: absolute;
    top: 18px;
    right: 54px;
  }

  // HISTORY
  .OrderTile_history {
    position: absolute;
    top: 1px;
    right: 0;

    padding: 8px;
    margin-right: -8px;
    margin-top: -8px;
  }

  // BADGES
  .OrderTile_badges {
    display: flex;
    align-items: center;
    gap: 8px;
    width: calc(100% - 120px);
    flex-wrap: wrap;

    z-index: 1;
    position: relative;
  }

  // TAGS
  .OrderTile_tags {
    display: flex;
    align-items: center;
    margin-top: 13px;

    p {
      display: flex;
      align-items: center;
      font-family: "Mulish Bold";
      height: 24px;
      font-size: 0.625rem; // 10px
      margin: 0 22px 0 0;

      img {
        margin-right: 5px;
      }
    }
  }

  // DIVIDER
  .OrderTile_divider {
    border-top: 1px solid ${colours.lightGrey};
    margin-bottom: 23px;
  }

  // TRACKING LINK
  .OrderTile_trackingLink {
    font-family: "Mulish Regular";
    font-size: 0.625rem; // 10px
    color: #666666;
    margin-top: 0;

    a {
      font-family: "Mulish Regular";
      font-size: 0.625rem; // 10px
      color: #666666;
    }
  }

  // BUTTON
  button {
    margin-top: 33px;
  }
`

const handleDeliveryType = (deliveryType: OrderDeliveryType | 'cart_delivery' | 'cart_payment' | 'cart_payment_only' ): string => {
  switch (deliveryType) {
    case OrderDeliveryType.Express:
      return 'background: linear-gradient(to bottom, #FABD4A, #F67329 90%);'
    case OrderDeliveryType.Free:
    case OrderDeliveryType.Standard:
      return 'background: linear-gradient(to bottom, #6DDD96, #67D3F7 90%);'
    case OrderDeliveryType.ExternalPickup:
    case OrderDeliveryType.Pickup:
      return 'background: linear-gradient(to bottom, #AE44B4, #6D5BD7 90%);'
    case OrderDeliveryType.SameDay:
      return 'background: linear-gradient(to bottom, #AE44B4, #CF124B 90%);'
    case OrderDeliveryType.Custom:
      return 'background: linear-gradient(to bottom, #73AAEB, #1239C5 90%);'
    case 'cart_delivery':
      return 'background: linear-gradient(to bottom, #52E3D9, #0E4A70 90%);'
    case 'cart_payment':
    case 'cart_payment_only':
      return 'background: linear-gradient(to bottom, #F591E1, #942B7F 90%);'
    default:
      return '';
  }
};

const StyledTimeAgo = styled.p<{ urgency: OrderUrgency }>`
  position: absolute;
  top: 52px;
  right: 54px;
  width: fit-content;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.625rem; // 10px
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  ${props => handleUrgency(props.urgency)};
`

const handleUrgency = (urgency: OrderUrgency): string => {
  switch (urgency) {
    case OrderUrgency.Standard:
      return `
        background: #FCFCFC;
        border-color: #EFEFEF;
        color: #AEAEAE;
      `;
    case OrderUrgency.Warning:
      return `
        background: #FDEFEB;
        border-color: #FEE1D1;
        color: #E5721E;
        font-family: "Mulish SemiBold";
        font-style: italic;
      `;
    case OrderUrgency.Overdue:
      return `
        background: #FDEBEF;
        border-color: #FED1D7;
        color: ${colours.alert};
        font-family: "Mulish SemiBold";
        font-style: italic;
      `;
    default:
      return '';
  }
};